import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload, DatePicker } from "antd";
import { useHistory } from "react-router";
import FormData from 'form-data';
import moment from 'moment';
//import FileUpload from './FileUpload';

const { TextArea } = Input;

const AddEvent = () => {

  const [title, setTitle] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventDesc, setEventDesc] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [status, setStatus] = useState('');
  const [file, setFile] = useState('');
  const [btnText, setBtnText] = useState('')
  const [btnUrl, setBtnUrl] = useState('')
  const [imgs, setImg] = useState('')

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));
  //event_title, event_date, event_description, status, video_link, event_file
  useEffect(() => {
    // console.log("HISTORY ", props)
    console.log("HISTORY ", history);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      // console.log("DAATA", history.location.state.status);
      setTitle(data.event_title)
      setEventDate(moment(data.event_date))
      setEventDesc(data.event_description)
      setVideoLink(data.video_link)
      setStatus(data.status)
      setImg(data.image_path)
      setFile(data.image_path)
      setBtnText(data.button_text)
      setBtnUrl(data.button_url)
    }
  }, [])

  const addOrEditEvent = () => {


  }

  return (
    <div>
      <h2>{history.location.state ? "Update Event" : "Add Event"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        {/* setTitle("")
      setEventDate("")
      setEventDesc("")
      setVideoLink("")
      setStatus("") */}
        <Form.Item
          label="Event Title"
          // name="Event Title"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please input event title',
          //   },
          // ]}
          required={true}
        >
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Event Date"
          // name="Event Date"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please select event date',
          //   },
          // ]}
          required={true}
        >
          <DatePicker value={eventDate} format={'DD-MM-YYYY'} onChange={(value) => setEventDate(value)} />
        </Form.Item>
        <Form.Item
          label="Short Description"
          // name="Short Description"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please input short description',
          //   },
          // ]}
          required={true}
        >
          <TextArea rows={4} value={eventDesc} maxLength="340" onChange={(e) => setEventDesc(e.target.value)} />
        </Form.Item>
        <div className="" style={{ display: 'flex', justifyContent: 'end', width: '54%', marginTop: '-18px' }}>
          {eventDesc.length}/340
        </div>
        {/* <Form.Item label="Video Link">
          <Input value={videoLink} onChange={(e) => setVideoLink(e.target.value)} />
        </Form.Item> */}
        <Form.Item
          label="Button Text"
          // name="Button Text"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please input button text',
          //   },
          // ]}
          required={true}
        >
          <Input value={btnText} onChange={(e) => setBtnText(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Button URL"
          // name="Button URL"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please input button URL',
          //   },
          // ]}
          required={true}
        >
          <Input value={btnUrl} onChange={(e) => setBtnUrl(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Status"
          // name="Status"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please select status',
          //   },
          // ]}
          required={true}
        >
          <Radio.Group
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"Active"}>
              Active
            </Radio>
            <Radio value={"Inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Image"
          // name="Image"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please upload image',
          //   },
          // ]}
          required={true}
          help="*Please upload image of size 246px x 272px">
          <Input type='file' onChange={(e) => {
            setImg(URL.createObjectURL(e.target.files[0]),
              setFile(e.target.files[0]))
          }} />
          {imgs ? (<img src={imgs} height="90px" width="90px" />) : ""}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={addOrEditEvent} >
            {history.location.state ? "Update Event" : "Add Event"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEvent;
