import React from 'react';
import { Route, Redirect } from 'react-router-dom';


// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
    const token = JSON.parse(localStorage.getItem("token"));

    return (
        <Route
            {...rest}
            render={(props) => !token() ? <Component {...props} /> : <Redirect to={{ pathname: '/user' }} />}
        />
    )
}

export default PublicRoute;