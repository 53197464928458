import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import moment from 'moment';

const Event = () => {
  const [eventList, setEventList] = useState([]);
  const [eventListRecord, setEventListRecord] = useState({});
  const [action, setAction] = useState("");

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getEventList();
  }, []);

  useEffect(() => {
    console.log("eventListRecord", eventListRecord);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addevent",
            state: {
              status: eventListRecord,
            },
          },
          2000
        );
      });
    }
  }, [eventListRecord, action]);

  const getEventList = () => {

  };

  const deleteEvent = () => {

  };

  const columns = [
    {
      title: "Event Title",
      key: "event_title",
      dataIndex: "event_title",
      width: 100,
      ellipsis: true,

    },
    {
      title: "Event Date",
      key: "event_date",
      dataIndex: "event_date",
      width: 100,
      ellipsis: true,

      render: (data) => {
        return (
          moment(data).format("DD-MM-YYYY")
        )
      }
    },
    // {
    //   title: "Key Quote",
    //   key: "quote",
    //   dataIndex: "key_quote",
    // },//instagram_link, facebook_link, linkedin_link, twitter_link
    {
      title: "Event Description",
      key: "event_description",
      dataIndex: "event_description",
      ellipsis: true,
      width: 300
    },
    // {
    //   title: "Event File",
    //   key: "event_file",
    //   dataIndex: "image_path",
    //   render: (url) => {
    //     return(

    //         <Button type="primary" href = {url}>
    //           Download File
    //         </Button>

    //     )
    //   }
    // },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />}
              type="primary"
            ></Button>

            <Popconfirm
              title="Are you sure to delete this event?"
              onConfirm={deleteEvent}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Events</h2>
        <Link to="/addevent">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add Event
          </Button>
        </Link>
      </div>
      <Table
        // loading={eventList.length > 0 ? false : true}
        columns={columns}
        dataSource={eventList}

        onRow={(record) => ({
          onClick: () => {
            setEventListRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Event;
