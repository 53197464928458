import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload } from "antd";
import { useHistory } from "react-router";
import FormData from 'form-data';
import api from "../../api";
//import FileUpload from './FileUpload';

const { TextArea } = Input;

const AddTeam = () => {

  const [fullName, setFullName] = useState('');
  const [designation, setDesignation] = useState('');
  const [linkdin, setLinkdin] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState('')
  const [status, setStatus] = useState('')
  const [role, setRole] = useState('');

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    console.log(history.location);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setFullName(data.name);
      setDesignation(data.designation);
      setLinkdin(data.linkedin_link)
      setDescription(data.description);
      setFile(data.image_url);
      setStatus(data.status);
      setRole(data.role);
    }
  }, [])

  const addOrEditTeamMember = () => {
    if (!fullName || !designation || !description || !linkdin || !file) {
      alert("Please enter all required entries")
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;

      let updatedData = new FormData()
      updatedData.append("team_members_id", data.team_members_id)
      updatedData.append("name", fullName)
      updatedData.append("designation", designation)
      updatedData.append("description", description)
      updatedData.append("linkedin_link", linkdin)
      updatedData.append("image_file", file)
      updatedData.append("status", status)
      updatedData.append('role', role)
      console.log("UPATE DATA", updatedData);

      api.teamCrud.updateTeam(updatedData, token).
        then(result => {
          console.log("RESULT update team member ", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setFullName("")
              setDesignation("")
              setDescription("")
              setLinkdin("")
              setFile("")
              setRole("")
              history.push('/team')
            }, 3500);
          } else {
            message.success(result.message)
          }
        }).catch(error => {
          console.log(error)
        })

    } else {
      let data = new FormData()
      data.append("name", fullName)
      data.append("designation", designation)
      data.append("description", description)
      data.append("linkedin_link", linkdin)
      data.append("image_file", file)
      data.append("status", status)
      data.append('role', role)


      console.log("DATA TOKEN ", data, " ", token)
      api.teamCrud.addTeam(data, token).
        then(result => {
          console.log("Result Add Team Menmber", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setFullName("")
              setDesignation("")
              setDescription("")
              setLinkdin("")
              setFile("")
              setRole('')
              history.push('/team')
            }, 3500);
          } else {
            message.error(result.message);
          }
        }).catch(error => {
          console.log(error)
        })
    }

  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 10,
        offset: 3,
      },
    },
  };


  return (
    <div>
      <h2>{history.location.state ? "Update Team" : "Add Team Member"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        {...formItemLayout}
        size={"default"}
        labelAlign={"right"}
      >
        <Form.Item
          label="Full Name"
          required={true}
        >
          <Input className="ml-3" value={fullName} onChange={(e) => setFullName(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Designation"
          required={true}
        >
          <Input className="ml-3" value={designation} onChange={(e) => setDesignation(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Description"
          required={true}
        >
          <TextArea className="ml-3" rows={4} value={description} maxLength="340" onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Photo"
          required={true}>
          <Input className="ml-3" type='file' onChange={(e) => {
            setFile(e.target.files[0])
          }} />
          {/* {imgs ? (<img src={imgs} height="90px" width="90px" />) : ""} */}
          <ul className="mt-2"><li>For Best Possible Result Upload The Photo In 160x160 Pixel
          </li></ul>

        </Form.Item>
        <Form.Item
          label="Linkedin URL"
        // required={true}
        >
          <Input className="ml-3" rows={4} value={linkdin} maxLength="340" onChange={(e) => setLinkdin(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Status"
          // name="Status"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please select status',
          //   },
          // ]}
          required={true}
        >
          <Radio.Group className="ml-3"
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"active"}>
              Active
            </Radio>
            <Radio value={"inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Role"
          required={true}
        >
          <Radio.Group className="ml-3"
            value={role} onChange={(e) => setRole(e.target.value)}>
            <Radio value={"Management"}>
              Management
            </Radio>
            <Radio value={"Advisor"}>
              Advisor
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button className="ml-3" type="primary" htmlType="submit" onClick={addOrEditTeamMember} >
            {history.location.state ? "Update Team" : "Add Team"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTeam;
