import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Checkbox, Radio, Upload, message, Result, Select } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { useHistory } from "react-router";
import moment from "moment";
import FormData from "form-data";
//import CKEditor from "react-ckeditor-component";
//import { CKEditor } from 'ckeditor4-react';
import api from "../../api";

import '../../style.css'
const { TextArea } = Input;

const AddCaseStudies = () => {
  const [casetitle, setCasetile] = useState("");
  const [projectName, setProjectName] = useState("");
  const [industry, setIndustry] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [highlightText, setHighlightText] = useState("");
  const [technology, setTechnology] = useState("");
  const [paraOneStatic, setParaOneStatic] = useState("");
  const [paraOneText, setParaOneText] = useState("");
  const [paraTwoStatic, setParaTwoStatic] = useState("");
  const [paraTwoText, setParaTwoText] = useState("");
  const [paraThreeStatic, setParaThreeStatic] = useState("");
  const [paraThreeText, setParaThreeText] = useState("");
  const [desc, setDesc] = useState("");
  const [fileList, setFileList] = useState([]);
  const [status, setStatus] = useState('');
  const [img, setImg] = useState("");
  const [featurepage, setFeaturepage] = useState("");
  const [datatype, setDatatype] = useState("");
  const [readmore, setReadmore] = useState('');


  const props = {
    onRemove: file => {

      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      // return {
      //   fileList: newFileList,
      //   };
      setFileList(newFileList)

    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));



  // const editData = async () => {

  // }

  useEffect(() => {
    console.log(history.location);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setCasetile(data.case_study_title);
      setProjectName(data.project_name);
      setIndustry(data.industry);
      setShortDesc(data.short_description)
      setHighlightText(data.highlight_text);
      setTechnology(data.technology)
      setStatus(data.status);
      setImg(data.case_study_image);
      setDatatype(data.data_type);
      setReadmore(data.read_more_link);
      setFeaturepage(data.feature_on_homepage);
    }
  }, [])

  const addOrEditBlog = () => {
    if (!casetitle || !highlightText || !status || !readmore || !img || !industry || !datatype || !technology) {
      alert("Please enter all required entries")
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;

      let updatedData = new FormData()
      updatedData.append("case_study_id", data.case_study_id)
      updatedData.append("case_study_title", casetitle)
      updatedData.append("project_name", projectName)
      updatedData.append("industry", industry)
      updatedData.append("short_description", shortDesc)
      updatedData.append("highlight_text", highlightText)
      updatedData.append("technology", technology)
      updatedData.append("status", status)
      updatedData.append("case_study_image", img)
      updatedData.append("data_type", datatype)
      updatedData.append("read_more_link", readmore)
      updatedData.append("feature_on_homepage", featurepage)


      console.log("UPATE DATA", updatedData);

      api.CaseStudiesCrud.updateCasestudies(updatedData, token).
        then(result => {
          console.log("RESULT update castestudies ", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setCasetile("")
              setProjectName("")
              setIndustry("")
              setShortDesc("")
              setHighlightText("");
              setTechnology("")
              setStatus("");
              setImg("");
              setDatatype("");
              setReadmore("");
              setFeaturepage("");
              history.push('/caseStudies')
            }, 3500);
          } else {
            message.success(result.message)
          }
        }).catch(error => {
          console.log(error)
        })

    } else {
      let data = new FormData()
      data.append("case_study_id", data.case_study_id)
      data.append("case_study_title", casetitle)
      // data.append("project_name", projectName)
      data.append("industry", industry)
      // data.append("short_description", shortDesc)
      data.append("highlight_text", highlightText)
      data.append("technology", technology)
      data.append("status", status)
      data.append("case_study_image", img)
      data.append("data_type", datatype)
      data.append("read_more_link", readmore)
      data.append("feature_on_homepage", featurepage)


      console.log("DATA TOKEN ", data, " ", token)
      api.CaseStudiesCrud.addCasestudies(data, token).
        then(result => {
          console.log("Result Add Team Menmber", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setCasetile("")
              setProjectName("")
              setIndustry("")
              setShortDesc("")
              setHighlightText("");
              setTechnology("")
              setStatus("");
              setImg("");
              setDatatype("");
              setReadmore("");
              setFeaturepage("");
              history.push('/caseStudies')
            }, 3500);
          } else {
            message.error(result.message);
          }
        }).catch(error => {
          console.log(error)
        })
    }

  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 18,
        offset: 0,
      },
      sm: {
        span: 10,
        offset: 3,
      },
    },
  };
  const { Option } = Select;
  return (

    <div>
      <h2 style={{ marginLeft: "4%" }}>{history.location.state ? "Update A Case Study" : "Add A Case Study"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        {...formItemLayout}
        size={"default"}
        labelAlign={"right"}
      >
        <Form.Item
          label="Title"
          required={true}

        >
          <Input className="ml-3" value={casetitle} onChange={(e) => setCasetile(e.target.value)} />
        </Form.Item>

        {/* <Form.Item
          label="Project Name"
          required={true}

        >
          <Input className="ml-3" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
        </Form.Item> */}

        <Form.Item
          label="Industry"
          required={true}

        >
          <Select className="ml-3"
            showSearch
            // style={{ width: 200 }}
            value={industry}
            placeholder="Select Industry "
            // optionFilterProp="children"
            onChange={(value) => { setIndustry(value); console.log(`selected ${value}`) }}
            onSearch={(val) => { console.log('search', val) }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select Industry</Option>
            <Option value="Manufacturing">Manufacturing</Option>
            <Option value="Banking & Finance" >Banking & Finance</Option>
            <Option value="Healthcare">Healthcare</Option>
            <Option value="Retail & E-Commerce">Retail & E-Commerce</Option>
            <Option value="Legal">Legal</Option>
          </Select>

        </Form.Item>
        <Form.Item
          label="Technology"
          required={true}

        >
          <Select className="ml-3"
            showSearch
            value={technology}
            placeholder="Select Technology "
            // optionFilterProp="children"
            onChange={(value) => { setTechnology(value); console.log(`selected ${value}`) }}
            onSearch={(val) => { console.log('search', val) }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Technology</Option>
            <Option value="Deep Learning / AI" >Deep Learning / AI</Option>
            <Option value="Computer vision (CV)">Computer vision (CV)</Option>
            <Option value="Natural language processing (NLP)">Natural language processing (NLP)</Option>
            <Option value="Cloud">Cloud</Option>
            <Option value="Data Analytics">Data Analytics</Option>
            <Option value="Web Applications">Web Applications</Option>



          </Select>
        </Form.Item>
        <Form.Item
          label="Data Type"
          required={true}

        >
          <Select className="ml-3"
            showSearch
            // style={{ width: 200 }}
            value={datatype}
            placeholder="Select Data Type "
            // optionFilterProp="children"
            onChange={(value) => { setDatatype(value); console.log(`selected ${value}`) }}
            onSearch={(val) => { console.log('search', val) }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Data Type</Option>
            <Option value="Image" >Image</Option>
            <Option value="Text">Text</Option>
            <Option value="Video">Video</Option>
            <Option value="Sound / Audio">Sound / Audio</Option>
            <Option value="Numerical">Numerical</Option>
          </Select>

        </Form.Item>
        <Form.Item
          label=" Highlight Text"
          required={true}

        >
          <Input className="ml-3" value={highlightText} onChange={(e) => setHighlightText(e.target.value)} />
        </Form.Item>
        {/* <Form.Item
          label="Parameter 1 Statistic"
        // required={true}

        >
          <Input className="ml-3" value={paraOneStatic} onChange={(e) => setParaOneStatic(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Parameter 1 Text"
        // required={true}

        >
          <Input className="ml-3" value={paraOneText} onChange={(e) => setParaOneText(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Parameter 2 Statistic"
        // required={true}

        >
          <Input className="ml-3" value={paraTwoStatic} onChange={(e) => setParaTwoStatic(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Parameter 2 Text"
        // required={true}

        >
          <Input className="ml-3" value={paraTwoText} onChange={(e) => setParaTwoText(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Parameter 3 Statistic"
        // required={true}

        >
          <Input className="ml-3" value={paraThreeStatic} onChange={(e) => setParaThreeStatic(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Parameter 3 Text"
        // required={true}

        >
          <Input className="ml-3" value={paraThreeText} onChange={(e) => setParaThreeText(e.target.value)} />
        </Form.Item> */}

        {/* <Form.Item
          label="Detailed Description"
        // required={true}
        >
          <TextArea className="ml-3" value={shortDesc} rows={4} onChange={(e) => setShortDesc(e.target.value)} />
        </Form.Item> */}
        <Form.Item
          label="Read More"
          required={true}

        >
          <Input type="url" className="ml-3" value={readmore} onChange={(e) => setReadmore(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Casestudy Image"
          required={true}>
          <Input className="ml-3" type='file' onChange={(e) => {
            setImg(e.target.files[0])
          }} />

          <ul className="mt-2"><li>For Best Possible Result Upload The Photo In 430 x 338 Pixel</li></ul>
        </Form.Item>
        <Form.Item
          label='Status'
          required={true}
        >
          <Radio.Group className="ml-3"
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"Active"}>
              Active
            </Radio>
            <Radio value={"Inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='Feature On Home Page'
        >
          <Radio.Group className="ml-3"
            value={featurepage} onChange={(e) => setFeaturepage(e.target.value)}>
            <Radio className='mr-3' value={"Yes"} >
              Yes
            </Radio>
            <Radio className="ml-2" value={"No"}>
              No
            </Radio>
          </Radio.Group>

        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button
            htmlType="submit"
            type="primary" onClick={() => {
              addOrEditBlog();
            }} style={{ marginLeft: "34%" }}>{history.location.state ? "Update Case Study" : "Add Case Study"}</Button>
        </Form.Item>
      </Form>
    </div >
  );
};

export default AddCaseStudies;
