import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../../api";


const Demoapp = () => {


    const [selectedUser, setSelectedUser] = useState({});
    const [demoList, setDemoList] = useState([]);
    const [action, setAction] = useState("");
    const [fixedTop, setFixedTop] = useState(false);

    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        getDemoList()
    }, []);



    const getDemoList = () => {
        api.demoappCrud.getDemo(token).then(result => {
            console.log("result get users", result);
            if (result.status === 1) {
                setDemoList(result.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const columns = [
        {
            title: "DemoApp Name",
            key: "demo_app_name",
            // width: 100,
            dataIndex: "demo_app_name",
            ellipsis: true,

        },
        {
            title: "Short Description",
            key: "short_description",
            // width: 100,
            dataIndex: "short_description",
            ellipsis: true,

        },
        {
            title: "Industry",
            key: "industry",
            // width: 100,
            dataIndex: "industry",
            ellipsis: true,

        },
        {
            title: "Technology",
            key: "technology",
            // width: 100,
            dataIndex: "technology",
            ellipsis: true,

        },
        {
            title: "Data Type",
            key: "data_type",
            // width: 100,
            dataIndex: "data_type",
            ellipsis: true,

        },
        {
            title: "Try Demo",
            key: "try_demo",
            // width: 100,
            dataIndex: "try_demo",
            ellipsis: true,
            render: (try_demo) => {
                return (
                    <a href={try_demo} target="_blank">{try_demo}</a>
                );
            }

        },
        {
            title: "Play Demo",
            key: "play_demo",
            // width: 100,
            dataIndex: "play_demo",
            ellipsis: true,
            render: (play_demo) => {
                return (
                    <a href={play_demo} target="_blank">{play_demo}</a>
                );
            }

        },
        {
            title: "Status",
            key: "status",
            // width: 100,
            dataIndex: "status",
            ellipsis: true,

        },

        {
            title: "Actions",
            key: "action",
            // width: 120,
            dataIndex: "action",
            render: () => {
                return (
                    <div style={{ width: "100%" }}>
                        <Button
                            onClick={() => {
                                setAction("EDIT");
                            }}
                            icon={<EditOutlined />} type="primary"></Button>

                        <Popconfirm
                            title="Are you sure to delete this user?"
                            onConfirm={deleteDemo}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                type="primary"
                                danger
                                style={{ marginLeft: "10px" }}
                            ></Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        console.log("selected user", selectedUser);
        if (action === "EDIT") {
            setTimeout(() => {
                history.push({
                    pathname: '/adddemoapp',
                    state: {
                        status: selectedUser,
                    }
                }, 2000);
            })
        }
    }, [selectedUser, action])

    const deleteDemo = () => {
        let data = {
            demo_app_id: selectedUser.demo_app_id
        }
        console.log("DATA ", data)
        console.log("TOKEN ", token)
        api.demoappCrud.deleteDemo(data, token)
            .then(result => {
                console.log("DELETE User", result);
                if (result.status === 1) {
                    message.success(result.message)
                    getDemoList();
                } else {
                    message.error(result.message)
                }
            }).catch(error => {
                console.log(error)
            })
    }
    return (
        <div>
            <div>
                <h2 style={{ display: "inline-block" }}>Demo Apps</h2>
                <Link to="/adddemoapp">
                    <Button type="primary" style={{ float: "right", top: "50%" }}>
                        Add Demo App
                    </Button>
                </Link>
            </div>
            <Table columns={columns} dataSource={demoList}
                //scroll={{ x: 'max-content' }}
                loading={demoList.length > 0 ? false : true}
                onRow={record => ({
                    onClick: () => {
                        // console.log("TABLE DATA ", (record))
                        setSelectedUser(record);
                    }
                })}
            // scroll={{ x: 1500 }}
            // summary={pageData => (
            //     <Table.Summary fixed={fixedTop ? 'top' : 'bottom'}>
            //         <Table.Summary.Row>
            //             <Table.Summary.Cell index={0} colSpan={2}>

            //             </Table.Summary.Cell>
            //             <Table.Summary.Cell index={2} colSpan={8}>

            //             </Table.Summary.Cell>
            //             <Table.Summary.Cell index={8}></Table.Summary.Cell>
            //         </Table.Summary.Row>
            //     </Table.Summary>
            // )}
            // sticky

            />
        </div>
    );
};

export default Demoapp;
