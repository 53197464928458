import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message } from "antd";
import { useHistory } from "react-router";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import api from "../../api";
import FormData from 'form-data';
import ReactHtmlParser from 'react-html-parser'

const { TextArea } = Input;

const AddCareer = () => {

  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [neededExp, setNeededExp] = useState('');
  const [Preq, setPreq] = useState('');
  const [desc, setDesc] = useState('');
  const [url, setUrl] = useState('');
  // const [data, setData] = useState('');
  // const [dataone, setDataOne] = useState('');


  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));


  useEffect(() => {
    console.log(history.location);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setType(data.career_type);
      setTitle(data.job_title);
      setNeededExp(data.experience_needed)
      setDesc(data.description);
      setPreq(data.prerequisite);
      setStatus(data.status);
    }
  }, [])


  const addOrEditEnquiry = () => {


    if (!type || !title || !status || !neededExp) {
      alert("Please enter all required entries")
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      // console.log(data.careers_id)
      let updatedData = {
        "careers_id": data.careers_id,
        "career_type": type,
        "job_title": title,
        "experience_needed": neededExp,
        "prerequisite": Preq,
        "description": desc,
        "status": status
      }



      console.log("UPATE DATA", ReactHtmlParser(updatedData));

      api.careerCrud.updateCar(updatedData, token).
        then(result => {
          console.log("RESULT update career", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setType("")
              setTitle("")
              setNeededExp("")
              setPreq("")
              setDesc("")
              history.push('/career')
            }, 3500);
          } else {
            message.success(result.message)
          }
        }).catch(error => {
          console.log(error)
        })

    } else {
      // console.log("career_type", type, "job_title", title,
      //   "experience_needed", neededExp,
      //   "prerequisite", Preq,
      //   "description", desc,
      //   "status", status)
      let data = {
        "career_type": type,
        "job_title": title,
        "experience_needed": neededExp,
        "prerequisite": Preq,
        "description": desc,
        "status": status
      }


      console.log("DATA TOKEN ", data, " ", token)
      api.careerCrud.addCar(data, token).
        then(result => {
          console.log("Result Add Career", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setType("")
              setTitle("")
              setNeededExp("")
              setPreq("")
              setDesc("")
              history.push('/career')
            }, 3500);
          } else {
            message.error(result.message);
          }
        }).catch(error => {
          console.log(error)
        })
    }

  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 10,
        offset: 3,
      },
    },
  };


  return (
    <div>

      <h2 style={{ marginLeft: "4%" }}>
        {history.location.state ? "Update Career Opportunity" : "Add Career Opportunity"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        {...formItemLayout}
        size={"default"}
        labelAlign={"right"}
      >
        <Form.Item
          label="Status"
          required={true}
        >
          <Radio.Group className="ml-3"
            value={type} onChange={(e) => setType(e.target.value)}>
            <Radio value={"FullTime"}>
              Full Time Opportunity
            </Radio>
            <Radio value={"Internship"}>
              Internship
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Job Title">
          <Input className="ml-3" value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Experience Needed">
          <Input className="ml-3" value={neededExp} onChange={(e) => setNeededExp(e.target.value)} />
        </Form.Item>
        <Form.Item label="About The Role">
          {/* <TextArea rows={3} value={desc} onChange={(e) => setDesc(e.target.value)} /> */}
          <p className="ml-3">
            <CKEditor editor={ClassicEditor}
              onReady={(editor) => {
                editor.setData(desc);
                editor.ui.view.editable.element.style.minHeight = "100px";
              }}

              onChange={(event, editor) => {
                const data = editor.getData();
                setDesc(data);
                console.log({ event, editor, data });
                // console.log(ReactHtmlParser(data));
              }}

            />
          </p>
        </Form.Item>

        <Form.Item label="Prerequisites">
          {/* <TextArea rows={3} value={desc} onChange={(e) => setDesc(e.target.value)} /> */}
          <p className="ml-3">
            <CKEditor editor={ClassicEditor}


              onReady={(editor) => {

                editor.setData(Preq);
                editor.ui.view.editable.element.style.minHeight = "100px";
              }}
              onInit={(editor) => {
                editor.setData(Preq);
                editor.editing.view.change((writer) => {
                  writer.setStyle(

                    editor.editing.view.document.getRoot()
                  );
                });

              }}

              onChange={(event, editor) => {

                const data = editor.getData();
                setPreq(data);
                console.log({ event, editor, data });
                // console.log(ReactHtmlParser(data));

              }}
            />
            <div>
              {/* <ifram src={Preq} style={{ border: 'none' }} /> */}
            </div>
          </p>
        </Form.Item>
        {/* <Form.Item label="Apply Now URL">
          <Input value={url} onChange={(e) => setUrl(e.target.value)} />
        </Form.Item> */}

        <Form.Item label='Status'
          required={true}>
          <Radio.Group className="ml-3"
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"active"}>
              Active
            </Radio>
            <Radio value={"inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>

          <Button className="ml-3" type="primary" style={{ marginLeft: "30%" }} onClick={addOrEditEnquiry}>
            {history.location.state ? "Update Career opportunity" : "Add Career opportunity"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddCareer;
