import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../../api";


const dataSource = [
  {
    key: '1',
    name: 'First Name',
    LastName: 'LastName',
    Mobile: "96663321",
    Email: "Email@gmail.com",
    Password: "Password",
  },
  {
    key: '2',
    name: 'First Name 2',
    LastName: 'LastName 2',
    Mobile: "96663321",
    Email: "Email2@gmail.com",
    Password: "Password2",
  },
  {
    key: '3',
    name: 'First Name 3',
    LastName: 'LastName 3',
    Mobile: "96663321",
    Email: "Email3@gmail.com",
    Password: "Password3",
  },
];

const User = () => {


  const [selectedUser, setSelectedUser] = useState({});
  const [userList, setUserList] = useState([]);
  const [action, setAction] = useState("");


  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getTeamMemberList()
  }, [])

  const getTeamMemberList = () => {
    api.userCrud.getUser(token).then(result => {
      console.log("result get user", result);
      if (result.status === 1) {
        setUserList(result.result)
        console.log(userList)
      }
    }).catch(error => {
      console.log(error)
    })
  }


  // console.log({ userList });

  // const deleteUser = () => {
  //   console.log("DELETE ", selectedUser);
  //   const filterData = userList.filter((data, idx) => {
  //     return (selectedUser.key !== data.key)
  //   })
  //   console.log("filter adad", filterData);
  //   setUserList(filterData)
  // }

  const columns = [
    {
      title: "First Name",
      key: "admin_name",
      width: 100,
      dataIndex: "admin_name",
      ellipsis: true,

    },
    {
      title: "Last Name",
      key: "admin_last_name",
      width: 100,
      dataIndex: "admin_last_name",
      ellipsis: true,

    },
    {
      title: "Mobile",
      key: "mobile",
      width: 100,
      dataIndex: "mobile",
      ellipsis: true,

    },
    {
      title: "Email",
      key: "admin_username",
      width: 100,
      dataIndex: "admin_username",
      ellipsis: true,

    },
    // {
    //   title: "Password",
    //   key: "admin_password",
    //   width: 100,
    //   dataIndex: "admin_password",
    // },

    {
      title: "Actions",
      key: "action",
      width: 120,
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />} type="primary"></Button>

            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={deleteUser}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("selected user", selectedUser);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addUser',
          state: {
            status: selectedUser,
          }
        }, 2000);
      })
    }
  }, [selectedUser, action])

  const deleteUser = () => {
    let data = {
      admin_id: selectedUser.admin_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.userCrud.deleteUser(data, token)
      .then(result => {
        console.log("DELETE User", result);
        if (result.status === 1) {
          message.success(result.message)
          getTeamMemberList();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  // const hideRow = () => {
  //   let data = history.location.state.status;

  //   var row = document.getElementById(data.admin_id);
  //   row.style.display = 'none';
  // }
  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Users</h2>
        <Link to="/addUser">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add User
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={userList}
        //scroll={{ x: 'max-content' }}

        // loading={true}
        // loading={userList.length > 0 ? false : true}
        onRow={record => ({
          onClick: () => {
            // console.log("TABLE DATA ", (record))
            setSelectedUser(record);
          }
        })}

      />
    </div>
  );
};

export default User;
