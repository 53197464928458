import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const Team = () => {

  const [teamList, setTeamList] = useState([]);
  const [action, setAction] = useState("");
  const [teamRecord, setTeamRecord] = useState({});

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getTeamList()
  }, [])

  const getTeamList = () => {
    api.teamCrud.getTeam(token).then(result => {
      console.log("result get team", result);
      if (result.status === 1) {
        setTeamList(result.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const dataSource = [
    {
      key: '1',
      Full_Name: 'Full Name',
      Designation: 'Designation',
      Description: "Some description",
      Photo: "Image1",
    },
    {
      key: '2',
      Full_Name: 'Full Name2',
      Designation: 'Designation2',
      Description: "Some description123",
      Photo: "Image2",
    },
    {
      key: '3',
      Full_Name: 'Full Name',
      Designation: 'Designation111',
      Description: "Some description3",
      Photo: "Image111",
    },
  ];

  const columns = [
    {
      title: "Full Name",
      key: "name",
      dataIndex: "name",
      ellipsis: true
    },
    {
      title: "Designation",
      key: "designation",
      dataIndex: "designation",
      ellipsis: true
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      ellipsis: true
    },
    {
      title: "Photo",
      key: 'image_url',
      dataIndex: 'image_url',
      render: (theImageURL) => {
        return (<div>
          <img alt={theImageURL} height='80px' width='80px' src={theImageURL} />
        </div>)
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      ellipsis: true
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />} type="primary"></Button>

            <Popconfirm
              title="Are you sure to delete this team member?"
              onConfirm={deleteTeam}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("teamRecord", teamRecord);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addTeam',
          state: {
            status: teamRecord,
          }
        }, 2000);
      })
    }
  }, [teamRecord, action])


  const deleteTeam = () => {
    let data = {
      team_members_id: teamRecord.team_members_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.teamCrud.deleteTeam(data, token)
      .then(result => {
        console.log("DELETE TEAM member", result);
        if (result.status === 1) {
          message.success(result.message)
          getTeamList();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  return (
    <div>
      <div>
        <h2 style={{ display: "inline" }}>Team </h2>
        <Link to="/addTeam">
          <Button type="primary" style={{ float: "right", marginBottom: "15px" }}>
            Add Team
          </Button>
        </Link>
      </div>
      <Table columns={columns} dataSource={teamList}
        loading={teamList.length > 0 ? false : true}
        onRow={record => ({
          onClick: () => {
            setTeamRecord(record);
          }
        })}
      />
    </div>
  );
};

export default Team;
