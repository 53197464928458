import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const OurCustomers = () => {

  const [careerData, setCareerData] = useState([]);
  const [action, setAction] = useState("");
  const [careerRecord, setCareerRecord] = useState({});

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  //data reneder
  useEffect(() => {
    getCustomerList()
  }, [])

  const getCustomerList = () => {
    api.customerCrud.getCustomer(token).then(result => {
      console.log("result get cutomer", result);
      if (result.status === 1) {
        setCareerData(result.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const dataSource = [
    {
      key: '1',
      Customer_name: 'Customer_name',
      Customer_logo: 'logo',
      DOS: "Description1",
    },
    {
      key: '2',
      Customer_name: 'Customer_name2',
      Customer_logo: 'logo2',
      DOS: "Description2",
    },
    {
      key: '3',
      Customer_name: 'Customer_name3',
      Customer_logo: 'logo3',
      DOS: "Description3",
    },
  ];

  const columns = [
    {
      title: "Customer Name",
      key: "Customer Name",
      dataIndex: "customer_name",
      ellipsis: true,

    },
    {
      title: "Customer Logo",
      key: "Customer Logo",
      dataIndex: "customer_logo",
      ellipsis: true,

      render: (theImageURL) => {
        return (<div>
          <img alt="logo" height='80px' width='80px' src={theImageURL} />
        </div>)
      }
    },

    {
      title: "Display Order Sequence",
      key: "Display Order Sequence",
      dataIndex: "display_order_sequence",
      ellipsis: true,

    },

    {
      title: "Actions",
      key: "action",
      dataIndex: "status",
      width: 120,
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />} type="primary"></Button>

            <Popconfirm
              title="Are you sure to delete this our customers?"
              onConfirm={deleteCustomer}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("careerRecord", careerRecord);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addcustomer',
          state: {
            status: careerRecord,
          }
        }, 2000);
      })
    }
  }, [careerRecord, action])


  const deleteCustomer = () => {
    let data = {
      customers_id: careerRecord.customers_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.customerCrud.deleteCustomer(data, token)
      .then(result => {
        console.log("DELETE Customer", result);
        if (result.status === 1) {
          message.success(result.message)
          getCustomerList();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Our Customers</h2>
        <Link to="/addcustomer">
          <Button type="primary" style={{ float: "right", top: "8px" }}>
            Add Customer
          </Button>
        </Link>
      </div>
      <Table columns={columns} dataSource={careerData}
        loading={careerData.length > 0 ? false : true}
        // expandable={true}
        //scroll={{ x: 'max-content' }}
        onRow={record => ({
          onClick: () => {
            setCareerRecord(record);
          }
        })}
      />
    </div>
  );
};

export default OurCustomers;
