import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload } from "antd";
import { useHistory } from "react-router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import api from "../../api";
import FormItem from "antd/lib/form/FormItem";
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddCustomer = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerLogo, setCustomerLogo] = useState([]);
  const [order, setOrder] = useState("");
  const [status, setStatus] = useState('');
  const [imgs, setImgs] = useState('');


  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    console.log(history.location);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setCustomerName(data.customer_name);
      setCustomerLogo(data.customer_logo);
      setOrder(data.display_order_sequence)
      setStatus(data.status);

    }
  }, [])


  const addOrEditCareer = () => {
    if (!customerName || !customerLogo || !order || !status) {
      alert("Please enter all required entries")
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;

      let updatedData = new FormData()
      updatedData.append("customers_id", data.customers_id)
      updatedData.append("customer_name", customerName)
      updatedData.append("customer_logo", customerLogo)
      updatedData.append("display_order_sequence", order)
      updatedData.append("status", status)
      // updatedData.append("image_file", file)

      console.log("UPATE DATA", updatedData);

      api.customerCrud.updateCustomer(updatedData, token).
        then(result => {
          console.log("RESULT update team member ", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setCustomerName("")
              setCustomerLogo("")
              setOrder("")
              setStatus("")
              // setFile("")
              history.push('/ourCustomers')
            }, 3500);
          } else {
            message.success(result.message)
          }
        }).catch(error => {
          console.log(error)
        })

    } else {
      // sequence
      // let seq = order.split(',')
      // const imgSeq = seq.map((item) => {
      //   return (customerLogo[Number(item) - 1])
      // })
      // console.log(imgSeq)
      let data = new FormData()
      data.append("customer_name", customerName)
      // data.append("customer_logo", imgSeq)
      data.append("customer_logo", customerLogo)
      data.append("display_order_sequence", order)
      // data.append("linkedin_link", linkdin)
      // data.append("image_file", file)
      data.append("status", status)


      console.log("DATA TOKEN ", data, " ", token)
      api.customerCrud.addCustomer(data, token).
        then(result => {
          console.log("Result Add Team Menmber", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setCustomerName("")
              setCustomerLogo("")
              setOrder("")
              setStatus("")
              history.push('/ourCustomers')
            }, 3500);
          } else {
            message.error(result.message);
          }
        }).catch(error => {
          console.log(error)
        })
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 10,
        offset: 3,
      },
    },
  };

  const props = {
    onRemove: file => {

      const index = customerLogo.indexOf(file);
      const newFileList = customerLogo.slice();
      newFileList.splice(index, 1);
      // return {
      //   fileList: newFileList,
      //   };
      setCustomerLogo(newFileList)
      // konta state ahe
    },
    beforeUpload: file => {
      setCustomerLogo([...customerLogo, file]);
      console.log(customerLogo)
      return false;
    },
    customerLogo,

  };


  return (
    <div>
      <h2>{history.location.state ? "Update A Customer" : "Add A Customer"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        {...formItemLayout}
        size={"default"}
        labelAlign={"right"}
      >
        <Form.Item
          label="Customer Name"
          required={true}

        >
          <Input className="ml-3" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Photo"
          required={true}
        >
          <Input className="ml-3" type='file' onChange={(e) => {
            setCustomerLogo(e.target.files[0])
          }} multiple={true} />
          <ul className="mt-2"><li>For Best Possible Result Upload The Photo In 108 x 32 Pixel</li></ul>
          {/* {imgs ? (<img src={imgs} height="90px" width="90px" />) : ""}  */}
        </Form.Item>
        {/* <Form.Item label="Photo" required={true}>
          <Upload {...props} className="ml-3">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item> */}
        <Form.Item
          label="Display Order Sequence"
          required={true}
        >
          <Input className="ml-3" value={order} onChange={(e) => setOrder(e.target.value)} />
        </Form.Item>
        <Form.Item
          label='Status'
          required={true}
        >
          <Radio.Group className="ml-3"
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"active"}>
              Active
            </Radio>
            <Radio value={"inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>


        <Form.Item {...tailFormItemLayout}>
          <Button className="ml-3" type="primary" htmlType="submit" onClick={() => {
            addOrEditCareer()
          }}>{history.location.state ? "Update Customer" : "Add Customer"}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddCustomer;
