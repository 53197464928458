import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../../api";

const Announcement = () => {
    const [announceRecord, setAnnounceRecord] = useState({});
    const [announceList, setAnnounceList] = useState([]);
    const [action, setAction] = useState("");


    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        getAnnounceList()
    }, []);



    const getAnnounceList = () => {
        api.announcementCrud.getAnnouncement(token).then(result => {
            console.log("result get cutomer", result);
            if (result.status === 1) {
                setAnnounceList(result.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const columns = [
        {
            title: "Text",
            key: "marquee_text",
            width: 100,
            dataIndex: "marquee_text",
            ellipsis: true,

        },
        {
            title: "URL",
            key: "marquee_url",
            width: 100,
            dataIndex: "marquee_url",
            ellipsis: true,

        },
        {
            title: "Status",
            key: "marquee_status",
            width: 100,
            dataIndex: "marquee_status",
            ellipsis: true,

        },

        {
            title: "Actions",
            key: "action",
            width: 120,
            dataIndex: "action",
            render: () => {
                return (
                    <div style={{ width: "100%" }}>
                        <Button
                            onClick={() => {
                                setAction("EDIT");
                            }}
                            icon={<EditOutlined />} type="primary"></Button>

                        {/* <Popconfirm
                            title="Are you sure to delete this Announcement?"
                            onConfirm={deleteAnnouncement}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                type="primary"
                                danger
                                style={{ marginLeft: "10px" }}
                            ></Button>
                        </Popconfirm> */}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        console.log("announceRecord", announceRecord);
        if (action === "EDIT") {
            setTimeout(() => {
                history.push({
                    pathname: '/addAnnouncement',
                    state: {
                        status: announceRecord,
                    }
                }, 2000);
            })
        }
    }, [announceRecord, action])

    const deleteAnnouncement = () => {
        let data = {
            marquee_id: announceRecord.marquee_id
        }
        console.log("DATA ", data)
        console.log("TOKEN ", token)
        api.announcementCrud.deleteAnnouncement(data, token)
            .then(result => {
                console.log("DELETE announcement", result);
                if (result.status === 1) {
                    message.success(result.message)
                    getAnnounceList();
                } else {
                    message.error(result.message)
                }
            }).catch(error => {
                console.log(error)
            })
    }
    return (
        <div>
            <div>
                <h2 style={{ display: "inline-block" }}>Announcement</h2>
                {/* <Link to="/addannouncement">
                    <Button type="primary" style={{ float: "right", top: "8px" }}>
                        Add Announcement
                    </Button>
                </Link> */}
            </div>
            <Table columns={columns} dataSource={announceList}
                //scroll={{ x: 'max-content' }}
                loading={announceList.length > 0 ? false : true}
                onRow={record => ({
                    onClick: () => {
                        // console.log("TABLE DATA ", (record))
                        setAnnounceRecord(record);
                    }
                })} />
        </div>
    );
}
export default Announcement;