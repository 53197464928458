import React, { useEffect, useState } from "react";
import { Table, Button, DatePicker, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddBlog from "./AddCaseStudies";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment";
import renderHTML from 'react-render-html';
import api from "../../api";


const CaseStudies = () => {

  const [caseStudyData, setCaseStudyData] = useState([]);
  const [action, setAction] = useState("");
  const [blogRecord, setBlogRecord] = useState({});

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getBlogList();
  }, [])

  useEffect(() => {
    console.log("Blog Record", blogRecord)
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addCaseStudies',
          state: {
            status: blogRecord
          }
        }, 2000)
      })
    }
  }, [blogRecord, action])

  const getBlogList = () => {
    api.CaseStudiesCrud.getCasestudies(token).then(result => {
      console.log("result get casestudies", result);
      if (result.status === 1) {
        setCaseStudyData(result.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const deleteCasestudies = () => {
    let data = {
      case_study_id: blogRecord.case_study_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.CaseStudiesCrud.deleteCasestudies(data, token)
      .then(result => {
        console.log("DELETE casestudies", result);
        if (result.status === 1) {
          message.success(result.message)
          getBlogList();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const dataSource = [
    {
      key: '1',
      Case: 'Case',
      project_name: 'project_name',
      Industry: "Industry",
      Technology: "Technology",
      short_desc: "short desc",
      Highlight: "Highlight"
    },
    {
      key: '2',
      Case: 'Case 2',
      project_name: 'project_name2',
      Industry: "Industry",
      Technology: "Technology",
      short_desc: "short desc",
      Highlight: "Highlight"
    },
    {
      key: '3',
      Case: 'Case 3',
      project_name: 'project_name 3',
      Industry: "Industry",
      Technology: "Technology",
      short_desc: "short desc",
      Highlight: "Highlight"
    },
  ];

  const columns = [
    {
      title: "Case Study Title",
      key: "case_study_title",
      dataIndex: "case_study_title",
      ellipsis: true
    },

    {
      title: "Industry",
      key: "industry",
      dataIndex: "industry",
      ellipsis: true,
    },
    {
      title: "Technology",
      key: "technology",
      dataIndex: "technology",
      ellipsis: true,
    },
    {
      title: "Data Type",
      key: "datatype",
      dataIndex: "data_type",
      ellipsis: true,
    },
    {
      title: "Highlight Text",
      key: "highlighttext",
      dataIndex: "highlight_text",
      ellipsis: true,
    },
    {
      title: "Read More ",
      key: "read_more_link",
      dataIndex: "read_more_link",
      ellipsis: true,
      render: (read_more_link) => {
        return (
          <a href={read_more_link} target="_blank">{read_more_link}</a>
        );
      }

    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "status",
      render: () => {
        return (
          <div>

            <Button icon={<EditOutlined />} type="primary"
              onClick={() => {
                setAction("EDIT")
              }}></Button>

            <Popconfirm
              title="Are you sure to delete this blog?"
              onConfirm={deleteCasestudies}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];


  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Case Studies</h2>
        <Link to="/addCaseStudies">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add Case Study
          </Button></Link>
      </div>
      <Table columns={columns} dataSource={caseStudyData}
        loading={caseStudyData.length > 0 ? false : true}
        onRow={record => ({
          onClick: () => {
            setBlogRecord(record)
          }
        })} />
    </div>
  );
};

export default CaseStudies;
