import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Empty } from "antd";
import { useHistory } from "react-router";
import FormData from "form-data";
import api from "../../api";
import "antd/dist/antd.css";
const { TextArea } = Input;

const AddUser = (props) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');

  //instagram_link, facebook_link, linkedin_link, twitter_link
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    console.log(history.location);

    if (history.location.state && history.location.state.status) {
      console.log(history.location.state.status);
      let data = history.location.state.status;
      // console.log(data)
      setFirstName(data.admin_name);
      setLastName(data.admin_last_name);
      setMobile(data.mobile);
      setEmail(data.admin_username);
      setPassword(data.admin_password);
      setStatus(data.status);

    }
  }, [])

  const addOrEditUser = () => {
    if (!firstName || !lastName || !mobile || !email || !password) {
      alert("Please enter all required entries")
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      console.log(data)
      let updatedData = {
        "admin_id": data.admin_id,
        "admin_name": firstName,
        "admin_last_name": lastName,
        "mobile": mobile,
        "admin_username": email
      }

      // updatedData.append("password", password)


      console.log("UPATE DATA", updatedData);

      api.userCrud.updateUser(updatedData, token).
        then(result => {
          console.log("RESULT update User ", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setFirstName("")
              setLastName("")
              setMobile("")
              setEmail("")
              // setPassword("")
              history.push('/')
            }, 3500);
          } else {
            message.success(result.message)
          }
        }).catch(error => {
          console.log(error)
        })

    } else {
      // console.log("admin_name", firstName,
      //   "admin_last_name", lastName,
      //   "mobile", mobile,
      //   "admin_username", email,
      //   "admin_password", password)
      let data = {
        "admin_name": firstName,
        "admin_last_name": lastName,
        "mobile": mobile,
        "admin_username": email,
        "admin_password": password,
        "status": status

        // data.append("is_active", status)

      }


      console.log("DATA TOKEN ", data, " ", token)
      api.userCrud.addUser(data, token).
        then(result => {
          console.log("Result Add User", result);
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setFirstName("")
              setLastName("")
              setMobile("")
              setEmail("")
              setPassword("")
              history.push('/')
            }, 3500);
          } else {
            message.error(result.message);
          }
        }).catch(error => {
          console.log(error)
        })
    }
    // message.success("Update User data succesfully ");
    // setTimeout(() => {
    //   history.push("/")
    // }, 2000);
  }

  // const validateMessages = {
  //   required: "Field is required!",
  // };
  // const passupdate = () => {
  //   if (password != "") {
  //     // return (
  //     //   <Input value={password} onChange={(e) => setPassword(e.target.value)} hidden />

  //     // )

  //   } else {
  //     return (<Form.Item
  //       label="Password"
  //       required={true}
  //     >

  //       <Input value={password} onChange={(e) => setPassword(e.target.value)} />
  //     </Form.Item>)
  //   }
  // }
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 10,
        offset: 3,
      },
    },
  };

  return (

    < div >
      <h2>{history.location.state ? "Update User" : "Add User"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        // labelCol={{ span: 3 }}
        // wrapperCol={{ span: 10 }}
        {...formItemLayout}
        size={"default"}
        labelAlign={"right"}
      // validateMessages={validateMessages}
      >
        <Form.Item
          label="First Name "
          required={true}
        >
          <Input className="ml-3" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Last Name"
          required={true}
        >
          <Input className="ml-3" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Mobile"
          required={true}
        >
          <Input className="ml-3" value={mobile} onChange={(e) => setMobile(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Email"
          required={true}
        >
          <Input className="ml-3" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item >
        <Form.Item
          label="Password"
          required={true}>
          <Input className="ml-3" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>
        <Form.Item
          label='Status'
          required={true}
        >
          <Radio.Group className="ml-3"
            value={status} onChange={(e) => setStatus(e.target.value)}>
            <Radio value={"active"}>
              Active
            </Radio>
            <Radio value={"inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button className="ml-3" type="primary" htmlType="submit" onClick={addOrEditUser} >
            {history.location.state ? "Update User" : "Add User"}
          </Button>
        </Form.Item>
      </Form>
    </div >
  );
};

export default AddUser;