import axios from "axios";
import { API_BASE_URL } from "./app_constants";
import Announcement from "./components/Announcement/announcement";
import Career from "./components/Career/Career";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import ReactHtmlParse from 'react-html-parser';

export default {
  auth: {
    login: (data) =>
      axios.post(API_BASE_URL + `/admin/login`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("LOGIN=====> res", res);
        return res.data;
      })
  },
  teamCrud: {
    getTeam: (token) =>
      axios.get(API_BASE_URL + `/admin/team_members/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("team member get=====> res", res);
        return res.data;
      }),

    addTeam: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("team member add=====> res", res);
        return res.data;
      }),

    updateTeam: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE TEAM MEMBER=====> res", res);
        return res.data;
      }),

    deleteTeam: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE TEAM MEMBER=====> res", res);
        return res.data;
      }),



    // getCustomer: (token) =>
    //   axios.get(API_BASE_URL + `/admin/team_members/get?token=${token}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     }
    //   }).then(res => {
    //     console.log("team member get=====> res", res);
    //     return res.data;
    //   }),


  },
  userCrud: {
    getUser: (token) =>
      axios.get(API_BASE_URL + `/admin/users/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("USER get=====> res", res);
        return res.data;
      }),
    addUser: (data, token) =>
      axios.post(API_BASE_URL + `/admin/users/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("USER add=====> res", res);
        return res.data;
      }),
    updateUser: (data, token) =>
      axios.post(API_BASE_URL + `/admin/users/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE USER=====> res", res);
        return res.data;
      }),
    deleteUser: (data, token) =>
      axios.post(API_BASE_URL + `/admin/users/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE USER=====> res", res);
        return res.data;
      }),
  },
  contactUs: {
    getContact: (token) =>
      axios.get(API_BASE_URL + `/admin/contact_us/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Contact get=====> res", res);
        return res.data;
      }),

    deleteContact: (data, token) =>
      axios.post(API_BASE_URL + `/admin/contact_us/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE contact=====> res", res);
        return res.data;
      }),
  },

  careerCrud: {
    getCar: (token) =>
      axios.get(API_BASE_URL + `/admin/careers/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("careers get=====> res", res);
        return res.data;
      }),
    addCar: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("CAREER add=====> res", res);
        return res.data;
      }),
    updateCar: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE CAREER=====> res", res);
        return res.data;
      }),
    deleteCar: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE CAREER=====> res", res);
        return res.data;
      }),
  },
  customerCrud: {
    getCustomer: (token) =>
      axios.get(API_BASE_URL + `/admin/customers/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("CUSTOMER get=====> res", res);
        return res.data;
      }),
    addCustomer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/customers/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Cutsomer add=====> res", res);
        return res.data;
      }),
    updateCustomer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/customers/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE CUSTOMER=====> res", res);
        return res.data;
      }),
    deleteCustomer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/customers/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE CUSTOMER=====> res", res);
        return res.data;
      }),
  },
  CaseStudiesCrud: {
    getCasestudies: (token) =>
      axios.get(API_BASE_URL + `/admin/case_studies/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("CASESTUDIES get=====> res", res);
        return res.data;
      }),
    addCasestudies: (data, token) =>
      axios.post(API_BASE_URL + `/admin/case_studies/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("CASESTUDIES add=====> res", res);
        return res.data;
      }),
    updateCasestudies: (data, token) =>
      axios.post(API_BASE_URL + `/admin/case_studies/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE CASESTUDIES=====> res", res);
        return res.data;
      }),
    deleteCasestudies: (data, token) =>
      axios.post(API_BASE_URL + `/admin/case_studies/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE CASESTUDIES=====> res", res);
        return res.data;
      }),


  },

  announcementCrud: {
    getAnnouncement: (token) =>
      axios.get(API_BASE_URL + `/admin/marquees/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Announcement get=====> res", res);
        return res.data;
      }),
    addAnnouncement: (data, token) =>
      axios.post(API_BASE_URL + `/admin/marquees/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Announcement add=====> res", res);
        return res.data;
      }),
    updateAnnouncement: (data, token) =>
      axios.post(API_BASE_URL + `/admin/marquees/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE Announcement=====> res", res);
        return res.data;
      }),
    deleteAnnouncement: (data, token) =>
      axios.post(API_BASE_URL + `/admin/marquees/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE Announcement=====> res", res);
        return res.data;
      }),


  },
  demoappCrud: {
    getDemo: (token) =>
      axios.get(API_BASE_URL + `/admin/demo_apps/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Demo get=====> res", res);
        return res.data;
      }),
    addDemo: (data, token) =>
      axios.post(API_BASE_URL + `/admin/demo_apps/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Demo add=====> res", res);
        return res.data;
      }),
    updateDemo: (data, token) =>
      axios.post(API_BASE_URL + `/admin/demo_apps/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE Demo=====> res", res);
        return res.data;
      }),
    deleteDemo: (data, token) =>
      axios.post(API_BASE_URL + `/admin/demo_apps/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE Demo=====> res", res);
        return res.data;
      }),

  },
  resumeCrud: {
    getResume: (token) =>
      axios.get(API_BASE_URL + `/admin/job_applications/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("Job Application get=====> res", res);
        return res.data;
      }),
    deleteResume: (data, token) =>
      axios.post(API_BASE_URL + `/admin/job_applications/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DELETE Application=====> res", res);
        return res.data;
      }),

  }
}