import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload } from "antd";
import { useHistory } from "react-router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import api from "../../api";
import FormItem from "antd/lib/form/FormItem";
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddAnnouncement = () => {
    const [customerName, setCustomerName] = useState("");
    const [customerLogo, setCustomerLogo] = useState([]);
    const [order, setOrder] = useState("");
    const [status, setStatus] = useState('');
    const [imgs, setImgs] = useState('');
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');




    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        console.log(history.location);
        if (history.location.state && history.location.state.status) {
            let data = history.location.state.status;
            setText(data.marquee_text);
            setUrl(data.marquee_url);
            setStatus(data.marquee_status);

        }
    }, [])


    const addOrEditAnnounce = () => {
        if (!text || !url || !status) {
            alert("Please enter all required entries")
            return;
        }

        if (history.location.state && history.location.state.status) {
            let data = history.location.state.status;

            let updatedData = {
                "marquee_id": data.marquee_id,
                "marquee_text": text,
                "marquee_url": url,
                "marquee_status": status
                // updatedData.append("image_file", file)
            }
            console.log("UPATE DATA", updatedData);

            api.announcementCrud.updateAnnouncement(updatedData, token).
                then(result => {
                    console.log("RESULT update Announcement ", result);
                    if (result.status === 1) {
                        message.success(result.message);
                        setTimeout(() => {
                            setText("")
                            setUrl("")
                            setStatus("")
                            // setFile("")
                            history.push('/announcement')
                        }, 3500);
                    } else {
                        message.success(result.message)
                    }
                }).catch(error => {
                    console.log(error)
                })

        } else {
            let seq = order.split(',')
            const imgSeq = seq.map((item) => {
                return (customerLogo[Number(item) - 1])
            })
            console.log(imgSeq)
            let data = {
                "marquee_text": text,
                "marquee_url": url,
                "marquee_status": status

            }
            console.log("DATA TOKEN ", data, " ", token)
            api.announcementCrud.addAnnouncement(data, token).
                then(result => {
                    console.log("Result Add Team Menmber", result);
                    if (result.status === 1) {
                        message.success(result.message);
                        setTimeout(() => {
                            setText("")
                            setUrl("")
                            setStatus("")

                            history.push('/announcement')
                        }, 3500);
                    } else {
                        message.error(result.message);
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 10 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 12,
                offset: 0,
            },
            sm: {
                span: 10,
                offset: 3,
            },
        },
    };

    const props = {
        onRemove: file => {

            const index = customerLogo.indexOf(file);
            const newFileList = customerLogo.slice();
            newFileList.splice(index, 1);
            // return {
            //   fileList: newFileList,
            //   };
            setCustomerLogo(newFileList)
            // konta state ahe
        },
        beforeUpload: file => {
            setCustomerLogo([...customerLogo, file]);
            console.log(customerLogo)
            return false;
        },
        customerLogo,

    };


    return (
        <div>
            <h2>{history.location.state ? "Update Announcement" : "Add Announcement"}</h2>
            <Form
                style={{ marginTop: "20px", marginLeft: "50px" }}
                {...formItemLayout}
                size={"default"}
                labelAlign={"right"}
            >
                <Form.Item
                    label="Announcement Text"
                    required={true}

                >
                    <Input className="ml-3" value={text} onChange={(e) => setText(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="URL"
                    required={true}
                >
                    <Input type="url" className="ml-3" value={url} onChange={(e) => setUrl(e.target.value)} />
                </Form.Item>
                <Form.Item
                    label='Status'
                    required={true}
                >
                    <Radio.Group className="ml-3"
                        value={status} onChange={(e) => setStatus(e.target.value)}>
                        <Radio value={"active"}>
                            Active
                        </Radio>
                        <Radio value={"inactive"}>
                            Inactive
                        </Radio>
                    </Radio.Group>
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button className="ml-3" type="primary" htmlType="submit" onClick={() => {
                        addOrEditAnnounce()
                    }}>{history.location.state ? "Update Announcement" : "Add Announcement"}</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddAnnouncement;
