import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Radio, Upload, message, Table, Popconfirm } from "antd";
import { useHistory } from "react-router";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { DownloadOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import api from "../../api";
import { Document, Page } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { BlobProvider } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
const { TextArea } = Input;

const Resume = () => {
  const [selectedResume, setSelectedResume] = useState({});
  const [resumeList, setResumeList] = useState([]);
  const [action, setAction] = useState("");
  const [show, setHide] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getResumeList()
  }, []);

  const getResumeList = () => {
    api.resumeCrud.getResume(token).then(result => {
      console.log("result get Resume", result);
      if (result.status === 1) {
        setResumeList(result.data)
        setHide(true)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const pdfview = (e) => {
    // Create a Blob from the PDF Stream
    // const file = new Blob([selectedResume.resume], { type: "application/pdf" });
    // console.log(file)
    // //Build a URL from the file
    // const fileURL = URL.createObjectURL(file);
    // console.log(fileURL);
    // // Open the URL on new Window

    const pdfWindow = window.open();
    pdfWindow.location.href = selectedResume.resume;
  }


  const MyDoc = (
    <Document>
      <Page>
        {selectedResume.resume}
      </Page>
    </Document>
  );

  const App = () => (
    <div>
      <BlobProvider document={MyDoc}>
        {/* <a href={selectedResume.resume} target="_blank" rel='noopener noreferrer'> */}
        {/* </a> */}

      </BlobProvider>
    </div>
  );

  const columns = [
    {
      title: "Full Name",
      key: "full_name",
      dataIndex: "full_name",
      ellipsis: true

    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      ellipsis: true

    },
    {
      title: "Phone Number",
      key: "phone",
      dataIndex: "phone",
      ellipsis: true

    },
    {
      title: "Job Title",
      key: "job_title",
      dataIndex: "job_title",
      ellipsis: true

    },
    {
      title: "Type",
      key: "job_type",
      dataIndex: "job_type",
      ellipsis: true

    },
    // {
    //   title: "Resume",
    //   key: "resume",
    //   dataIndex: "resume",
    //   ellipsis: true,
    //   render: () => {
    //     return (
    //       // <a href={selectedResume.resume} target="_blank" rel='noopener noreferrer'>
    //       <Button icon={<FilePdfOutlined />} onClick={pdfview}>
    //       </Button>
    //       //  </a> 
    //     )
    //   }
    // },

    {
      title: "Action",
      key: "action",
      dataIndex: "status",
      width: 120,
      render: () => {
        return (
          <div style={{ width: "100%" }}>


            <a href={selectedResume.resume} title="Download Resume">
              <Button
                icon={<DownloadOutlined />}
                type="primary"
              ></Button>

            </a>

            <Popconfirm
              title="Are you sure to delete this Resume?"
              onConfirm={deleteResume}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("selected resume", selectedResume);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addresume',
          state: {
            status: selectedResume,
          }
        }, 2000);
      })
    }
  }, [selectedResume, action])

  const deleteResume = () => {
    let data = {
      job_application_id: selectedResume.job_application_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.resumeCrud.deleteResume(data, token)
      .then(result => {
        console.log("DELETE resume", result);
        if (result.status === 1) {
          message.success(result.message)
          getResumeList();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  return (
    <div>
      <h2 style={{ marginLeft: "4%" }}>Recevied Resumes</h2>
      <Table columns={columns}
        dataSource={resumeList}
        loading={resumeList.length > 0 ? false : true}
        // expandable={true}
        //scroll={{ x: 'max-content' }}
        onRow={record => ({
          onClick: () => {
            setSelectedResume(record);
          }
        })}
      />
    </div>
  )
};

export default Resume;
