import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload, Select } from "antd";
import { useHistory } from "react-router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import api from "../../api";
import FormItem from "antd/lib/form/FormItem";
import { UploadOutlined, UserSwitchOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddDemoApp = () => {
    const [status, setStatus] = useState('');
    const [text, setText] = useState('');
    const [industry, setIndustry] = useState("");
    const [technology, setTechnology] = useState("");
    const [datatype, setDatatype] = useState("");
    const [trydemo, setTryDemo] = useState("");
    const [playdemo, setPlayDemo] = useState("");
    const [desc, setDesc] = useState("");
    const [image, setImage] = useState("");


    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("token"));
    const { Option } = Select;
    useEffect(() => {
        console.log(history.location);
        if (history.location.state && history.location.state.status) {
            let data = history.location.state.status;
            setText(data.demo_app_name);
            setIndustry(data.industry);
            setTechnology(data.technology);
            setDatatype(data.data_type);
            setTryDemo(data.try_demo);
            setPlayDemo(data.play_demo);
            setDesc(data.short_description);
            setImage(data.demo_app_image);
            setStatus(data.status);
        }
    }, [])


    const addOrEditDemo = () => {
        if (!text || !desc || !status || !industry || !technology || !datatype || !trydemo || !playdemo) {
            alert("Please enter all required entries")
            return;
        }

        if (history.location.state && history.location.state.status) {
            let data = history.location.state.status;

            let updatedData = new FormData();
            updatedData.append("demo_app_id", data.demo_app_id)
            updatedData.append("demo_app_name", text)
            updatedData.append("short_description", desc)
            updatedData.append("industry", industry)
            updatedData.append("technology", technology)
            updatedData.append("data_type", datatype)
            updatedData.append("try_demo", trydemo)
            updatedData.append("play_demo", playdemo)
            updatedData.append("demo_app_image", image)
            updatedData.append("status", status)

            // updatedData.append("image_file", file)

            console.log("UPATE DATA", updatedData);

            api.demoappCrud.updateDemo(updatedData, token).
                then(result => {
                    console.log("RESULT update Announcement ", result);
                    if (result.status === 1) {
                        message.success(result.message);
                        setTimeout(() => {
                            setText("");
                            setIndustry("");
                            setTechnology("");
                            setDatatype("");
                            setTryDemo("");
                            setPlayDemo("");
                            setDesc("");
                            setImage("");
                            setStatus("");
                            history.push('/demoapp')
                        }, 3500);
                    } else {
                        message.success(result.message)
                    }
                }).catch(error => {
                    console.log(error)
                })

        } else {


            let data = new FormData();
            data.append("demo_app_name", text)
            data.append("short_description", desc)
            data.append("industry", industry)
            data.append("technology", technology)
            data.append("data_type", datatype)
            data.append("try_demo", trydemo)
            data.append("play_demo", playdemo)
            data.append("demo_app_image", image)
            data.append("status", status)

            console.log("DATA TOKEN ", data, " ", token)
            api.demoappCrud.addDemo(data, token).
                then(result => {
                    console.log("Result Add Team Menmber", result);
                    if (result.status === 1) {
                        message.success(result.message);
                        setTimeout(() => {
                            setText("");
                            setIndustry("");
                            setTechnology("");
                            setDatatype("");
                            setTryDemo("");
                            setPlayDemo("");
                            setDesc("");
                            setImage("");
                            setStatus("");

                            history.push('/demoapp')
                        }, 3500);
                    } else {
                        message.error(result.message);
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 10 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 12,
                offset: 0,
            },
            sm: {
                span: 10,
                offset: 4,
            },
        },
    };



    return (
        <div>
            <h2>{history.location.state ? "Update Demo App" : "Add Demo App"}</h2>
            <Form
                style={{ marginTop: "20px", marginLeft: "50px" }}
                {...formItemLayout}
                size={"default"}
                labelAlign={"right"}
            >
                <Form.Item
                    label="Demo App Name"
                    required={true}

                >
                    <Input className="ml-3" value={text} onChange={(e) => setText(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Short Description"
                    required={true}
                >
                    <TextArea className="ml-3" rows={4} value={desc} onChange={(e) => setDesc(e.target.value)} maxLength='200' />
                </Form.Item>
                <Form.Item
                    label="Industry"
                    required={true}

                >
                    <Select className="ml-3"
                        showSearch
                        // style={{ width: 200 }}
                        value={industry}
                        placeholder="Select Industry "
                        // optionFilterProp="children"
                        onChange={(value) => { setIndustry(value); console.log(`selected ${value}`) }}
                        onSearch={(val) => { console.log('search', val) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Select Industry</Option>
                        <Option value="Manufacturing">Manufacturing</Option>
                        <Option value="Banking & Finance" >Banking & Finance</Option>
                        <Option value="Healthcare">Healthcare</Option>
                        <Option value="Retail & E-Commerce">Retail & E-Commerce</Option>
                        <Option value="Legal">Legal</Option>
                    </Select>

                </Form.Item>
                <Form.Item
                    label="Technology"
                    required={true}

                >
                    <Select className="ml-3"
                        showSearch
                        value={technology}
                        placeholder="Select Technology "
                        // optionFilterProp="children"
                        onChange={(value) => { setTechnology(value); console.log(`selected ${value}`) }}
                        onSearch={(val) => { console.log('search', val) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Technology</Option>
                        <Option value="Deep Learning / AI" >Deep Learning / AI</Option>
                        <Option value="Computer vision (CV)">Computer vision (CV)</Option>
                        <Option value="Natural language processing (NLP)">Natural language processing (NLP)</Option>
                        <Option value="Cloud">Cloud</Option>
                        <Option value="Data Analytics">Data Analytics</Option>
                        <Option value="Web Applications">Web Applications</Option>



                    </Select>
                </Form.Item>
                <Form.Item
                    label="Data Type"
                    required={true}

                >
                    <Select className="ml-3"
                        showSearch
                        // style={{ width: 200 }}
                        value={datatype}
                        placeholder="Select Data Type "
                        // optionFilterProp="children"
                        onChange={(value) => { setDatatype(value); console.log(`selected ${value}`) }}
                        onSearch={(val) => { console.log('search', val) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Data Type</Option>
                        <Option value="Image" >Image</Option>
                        <Option value="Text">Text</Option>
                        <Option value="Video">Video</Option>
                        <Option value="Sound / Audio">Sound / Audio</Option>
                        <Option value="Numerical">Numerical</Option>
                    </Select>

                </Form.Item>

                <Form.Item
                    label="Try Demo App URL"
                    required={true}
                >
                    <Input type="url" className="ml-3" value={trydemo} onChange={(e) => setTryDemo(e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Play Demo Video URL"
                    required={true}
                >
                    <Input type="url" className="ml-3" value={playdemo} onChange={(e) => setPlayDemo(e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Demo App Image"
                    required={true}
                >
                    <Input className="ml-3" type='file' onChange={(e) => {
                        setImage(e.target.files[0])
                    }} />
                    <ul className="mt-2"><li>For Best Possible Result Upload The Photo In 362 x 240 Pixel</li></ul>
                    {/* {imgs ? (<img src={imgs} height="90px" width="90px" />) : ""}  */}
                </Form.Item>

                <Form.Item
                    label='Status'
                    required={true}
                >
                    <Radio.Group className="ml-3"
                        value={status} onChange={(e) => setStatus(e.target.value)}>
                        <Radio value={"active"}>
                            Active
                        </Radio>
                        <Radio value={"inactive"}>
                            Inactive
                        </Radio>
                    </Radio.Group>
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button className="ml-3" type="primary" htmlType="submit" onClick={() => {
                        addOrEditDemo()
                    }}>{history.location.state ? "Update Demo App" : "Add Demo App"}</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddDemoApp;
