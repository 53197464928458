import React, { useEffect } from 'react';
import { Switch, Route, NavLink } from "react-router-dom";
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb, Button, message, Popconfirm, Avatar, Image } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, LogoutOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Resume from './components/ReceivedResumes/Resumes';
import Login from './components/Login/Login';
import Event from './components/Event/Event';
import AddEvent from './components/Event/AddEvent';
import User from './components/User/User';
import AddUser from './components/User/AddUser';
import OurCustomers from './components/Customer/OurCustomer';
import AddCustomer from './components/Customer/AddCustomer';
import AddCaseStudies from './components/CaseStudies/AddCaseStudies';
import CaseStudies from './components/CaseStudies/CaseStudies';
import AddTeam from './components/Team/AddTeam';
import Team from './components/Team/Team';
import AddCareer from './components/Career/AddCareer';
import Career from './components/Career/Career';
import ContactUs from './components/ContactUs/ContactUs';
import Announcement from './components/Announcement/announcement';
import AddAnnouncement from './components/Announcement/AddAnnouncement';
import Demoapp from './components/Demoapp/Demoapp';
import AddDemoApp from './components/Demoapp/Adddemoapp';
import PublicRoute from './services/utils/PublicRoutes'
import { Redirect, useHistory } from 'react-router';
import PrivateRoute from './services/utils/PrivateRoutes';
import axios from "axios";
import './components/navbar.css';

const { Header, Content, Sider, Footer } = Layout;


function App() {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false)
  const [login, setLogin] = useState(false)
  const [logout, setLogout] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    if (token) {
      setLogin(true)
    }
  }, [])

  if (login == false) {
    return (
      <Login
        loggedIn={data => setLogin(data)} />

    )
  }

  // PUBLISH DATA
  const publishData = () => {
    let url = `https://webapi.algoanalytics.com/api/admin/publish/get?token=${token}`;
    axios.get(url).then((res) => {
      // console.log("res", res);
      if(res.data.status == '1') {
        message.success('Data published successfully.');
      }
    });
  }


  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="header" style={{ padding: "0" }}>
          <span><Avatar className="ml-3 mr-3 mb-3" shape="square" src={<Image src="algoLogo.jpg" size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 80,
            xxl: 100,
          }} />} /></span>
          <span style={{ color: "white", fontWeight: 'bold', fontSize: '28px' }}>AlgoAnalytics</span>

              <Button 
                type="primary publish-btn"
                onClick={publishData}
              >
                Publish
              </Button>

              <Popconfirm
              title="Are you sure you want to logout?"
              onConfirm={() => {
                message.success("Logout successfully ")
                setTimeout(() => {
                  localStorage.removeItem("token");
                  setLogin(false);
                  // setLogout(true)
                }, 2000);
              }}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >

              <span style={{
                color: "white", 
                marginLeft: '75%', 
                cursor: 'pointer',
                fontSize: '1.1rem',
                marginTop: "-15px",
                position: 'relative',
                top: -5,
                right: 12,
              }}
              >
                <Button className=""

                  style={{ backgroundColor: '#001529', borderColor: '#001529', color: 'white', fontSize: '12px' }}
                  icon={<LogoutOutlined />}
                >
                </Button>
                Logout
              </span>
            </Popconfirm>
        </Header>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
              <Menu.Item key="1">
                <NavLink to='/'>
                  Users
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to='/ourCustomers'>
                  Our Customers
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key="3" >
                <NavLink to="/resume">
                  Recieved Resumes
                </NavLink>
              </Menu.Item> */}
              <Menu.Item key="3" >
                <NavLink to="/caseStudies">
                  Case Studies
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4" >
                <NavLink to="/team">
                  Team
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5" >
                <NavLink to="/career">
                  Careers
                </NavLink>
              </Menu.Item>
              <Menu.Item key="6" >
                <NavLink to="/demoapp">
                  Demo Apps
                </NavLink>
              </Menu.Item>

              <Menu.Item key="7" >
                <NavLink to="/contact">
                  Contact Us Request
                </NavLink>
              </Menu.Item>
              <Menu.Item key="8" >
                <NavLink to="/resume">
                  Received Resumes
                </NavLink>
              </Menu.Item>
              <Menu.Item key="9" >
                <NavLink to="/announcement">
                  Announcement
                </NavLink>
              </Menu.Item>
            </Menu>

          </Sider>
          <Layout style={{ padding: '0 24px 24px', }}>

            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch>

                <Route
                  exact
                  path='/'
                  component={User} />
                <Route
                  exact
                  path='/ourCustomers'
                  component={OurCustomers} />
                <Route
                  exact
                  path='/resume'
                  component={Resume} />
                <Route
                  exact
                  path='/caseStudies'
                  component={CaseStudies} />
                <Route
                  exact
                  path='/team'
                  component={Team} />
                <Route
                  exact
                  path='/career'
                  component={Career} />
                <Route
                  exact
                  path='/demoapp'
                  component={Demoapp} />
                <Route
                  exact
                  path='/adddemoapp'
                  component={AddDemoApp} />
                <Route
                  exact
                  path='/addCaseStudies'
                  component={AddCaseStudies} />
                <Route
                  exact
                  path='/addcustomer'
                  component={AddCustomer} />
                <Route
                  exact
                  path='/addcareer'
                  component={AddCareer} />
                <Route
                  exact
                  path='/contact'
                  component={ContactUs} />
                <Route
                  exact
                  path='/addUser'
                  component={AddUser} />
                <Route
                  exact
                  path='/addTeam'
                  component={AddTeam} />
                <Route
                  exact
                  path='/event'
                  component={Event} />
                <Route
                  exact
                  path='/addevent'
                  component={AddEvent} />
                <Route
                  exact
                  path='/announcement'
                  component={Announcement} />
                <Route
                  exact
                  path='/addAnnouncement'
                  component={AddAnnouncement} />
                <Route
                  exact
                  path='/privateroutes'
                  component={PrivateRoute} />
                <Route
                  exact
                  path='/publicrouts'
                  component={PublicRoute} />
                <Route
                  exact
                  path='/login'
                  component={App} />
                {/* <Route
                  exact
                  path='/login'
                  component={Login} /> */}
              </Switch>

            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: "white" }}></Footer>
            {/* <Footer style={{ textAlign: 'center', backgroundColor: "white" }}>© Copyright Cerebranium 2021 Created by AlphaOBS</Footer> */}
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
