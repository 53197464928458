import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const ContactUs = () => {

    const [enquiryData, setEnquiryData] = useState([]);
    const [action, setAction] = useState("");
    const [enquiryRecord, setEnquiryRecord] = useState({});

    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("token"));

    const columns = [
        {
            title: "Job Title",
            key: "job_title",
            dataIndex: "job_title",
            ellipsis: true,

        },
        {
            title: "Full Name",
            key: "Full Name",
            dataIndex: "full_name",
            ellipsis: true,

        },
        {
            title: "Phone",
            key: "Phone",
            dataIndex: "phone",
            ellipsis: true,

        },
        {
            title: "Email",
            key: "Email",
            dataIndex: "email",
            ellipsis: true,

        },
        {
            title: "Company Name",
            key: "Company Name",
            dataIndex: "company_name",
            ellipsis: true,

        },
        {
            title: "Reason",
            key: "reason",
            dataIndex: "reason",
            ellipsis: true,

        },
        {
            title: "How Did You Hear About Us ?",
            key: "How Did You Hear About Us ?",
            dataIndex: "how_did_you_hear",
            ellipsis: true,

        },
        {
            title: "Message",
            key: "Message",
            dataIndex: "message",
            ellipsis: true,

        },
        {
            title: "Actions",
            key: "action",
            dataIndex: "action",
            render: () => {
                return (
                    <div>

                        <Popconfirm
                            title="Are you sure to delete this contact?"
                            onConfirm={deleteContact}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                type="primary"
                                danger
                                style={{ marginLeft: "10px" }}
                            ></Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const getContact = () => {
        api.contactUs.getContact(token).then(result => {
            console.log("result get contact", result);
            if (result.status === 1) {
                setEnquiryData(result.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getContact()
    }, [])
    const deleteContact = () => {
        let data = {
            contact_us_id: enquiryRecord.contact_us_id
        }
        console.log("DATA ", data)
        console.log("TOKEN ", token)
        api.contactUs.deleteContact(data, token)
            .then(result => {
                console.log("DELETE Contact", result);
                if (result.status === 1) {
                    message.success(result.message)
                    getContact();
                } else {
                    message.error(result.message)
                }
            }).catch(error => {
                console.log(error)
            })
    }

    const getEnquiryList = () => {

    }


    return (
        <div>
            <div>
                <h2 style={{ display: "inline-block" }}>Contact Us Request</h2>
                {/* <Link to="/addenquiry">
        <Button type="primary" style={{ float: "right", marginBottom: "15px" }}>
          Add Enquiry
        </Button>
        </Link> */}
            </div>
            <Table columns={columns} dataSource={enquiryData}
                loading={enquiryData.length > 0 ? false : true}
                onRow={record => ({
                    onClick: () => {
                        setEnquiryRecord(record);
                    }
                })} />
        </div>
    );
};

export default ContactUs;
