import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const Career = () => {

  const [enquiryData, setEnquiryData] = useState([]);
  const [action, setAction] = useState("");
  const [enquiryRecord, setEnquiryRecord] = useState({});

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    getCareer()
  }, [])

  const getCareer = () => {
    api.careerCrud.getCar(token).then(result => {
      console.log("result get career", result);
      if (result.status === 1) {
        setEnquiryData(result.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const dataSource = [
    {
      key: '1',
      career_type: 'career_type',
      email: 'email@gmail.com',
      mobile_no: "9966441120",
      description: "description1",
    },
    {
      key: '2',
      career_type: 'lmn',
      email: 'email12@gmail.com',
      mobile_no: "9966441120",
      description: "description12",
    },
    {
      key: '3',
      career_type: 'abc',
      email: 'email123@gmail.com',
      mobile_no: "9966441120",
      description: "description123",
    },
  ];

  const columns = [
    {
      title: "Career Type",
      key: "career_type",
      dataIndex: "career_type",
      ellipsis: true,

    },
    {
      title: "Job Title",
      key: "job_title",
      dataIndex: "job_title",
      ellipsis: true,

    },

    {
      title: "Experience",
      key: "experience_needed",
      dataIndex: "experience_needed",
      ellipsis: true,

    },
    {
      title: "Prerequisite",
      key: "prerequisite",
      dataIndex: "prerequisite",
      ellipsis: true,
      render: (data) => {
        return (
          <iframe srcdoc={data} scrolling="no" style={{ border: "none" }} height="50px" />
        )
      }

    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div>
            <Button icon={<EditOutlined />} type="primary"
              onClick={() => {
                setAction("EDIT");
              }}></Button>
            <Popconfirm
              title="Are you sure to delete this Career?"
              onConfirm={deleteCar}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("enquiryRecord", enquiryRecord);
    if (action === "EDIT") {
      setTimeout(() => {
        history.push({
          pathname: '/addcareer',
          state: {
            status: enquiryRecord,
          }
        }, 2000);
      })
    }
  }, [enquiryRecord, action])


  const deleteCar = () => {
    let data = {
      careers_id: enquiryRecord.careers_id
    }
    console.log("DATA ", data)
    console.log("TOKEN ", token)
    api.careerCrud.deleteCar(data, token)
      .then(result => {
        console.log("DELETE Customer", result);
        if (result.status === 1) {
          message.success(result.message)
          getCareer();
        } else {
          message.error(result.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Careers</h2>
        <Link to="/addcareer">
          <Button type="primary" style={{ float: "right", marginBottom: "15px" }}>
            Add Career Opportunity
          </Button>
        </Link>
      </div>
      <Table columns={columns} dataSource={enquiryData}
        loading={enquiryData.length > 0 ? false : true}
        onRow={record => ({
          onClick: () => {
            setEnquiryRecord(record);
          }
        })} />
    </div>
  );
};

export default Career;
